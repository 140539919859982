<template>
  <div class="resolutionAudit">
    <backTitle lable='融资决议审核'></backTitle>
    <!-- 审核组件 -->
    <auditdetails
      :finReviewOpinionInfoVOS="finReviewOpinionInfoVO"
      processStatus='FINANCING_RESULT'
      auditType="RZJY"
      :type='type'
      :fileId="fileId"
      :fileType="fileType"
      :fileSources="fileSource"
      class="auditdetails"
    >
    </auditdetails>
  </div>
</template>
<script>
import backTitle from '@/pages/business/components/backtitle.vue'
import auditdetails from '../components/auditdetails.vue'
import { progressApi } from '@/api/businessApi'
export default {
  name: 'resolutionReviewAudit',
  components: { auditdetails, backTitle },
  data () {
    return {
      finReviewOpinionInfoVO: [],
      filelist: [
        {
          label: '合同文件',
          key: 'htwj',
          child: [],
          id: '1'
        },
        { label: '内部资料', key: 'nbzl', child: [], id: '2' },
        { label: '客户资料', key: 'khzl', child: [], id: '3' },
        { label: '担保人资料', key: 'dbrzl', child: [], id: '4' },
        { label: '标的资产相关', key: 'bdzcxg', child: [], id: '5' },
        { label: '其他资料', key: 'qtzl', child: [], id: '6' }
      ],
      bizId: '',
      type: '',
      fileId: '',
      fileType: '',
      fileSource: ''
    }
  },
  computed: {
    api () {
      return progressApi
    }
  },
  methods: {
    handleClick () {},
    getdetail () {
      const params = {
        businessId: this.bizId,
        instanceId: this.instanceId
      }
      this.api.getResultHandle(params).then((res) => {
        this.finReviewOpinionInfoVO = res.data.finReviewOpinionInfoVO
        this.fileId = res.data.fileId
        this.fileType = res.data.fileName ? res.data.fileName.split('.').pop() : ''
        this.fileSource = res.data.fileSource
        if (res.data.finElementImplementFileVO) {
          res.data.finElementImplementFileVO.forEach((item) => {
            this.filelist.forEach((elem) => {
              if (item.fileGroupCode === elem.key) {
                elem.child.push(item)
              }
            })
          })
        }
        console.log(res)
      })
    }
  },
  mounted () {
    this.type = this.$route.query.type
    this.bizId = this.type === 'E' ? JSON.parse(sessionStorage.getItem('examine')).bizId : JSON.parse(sessionStorage.getItem('examine')).keyId
    this.instanceId = JSON.parse(sessionStorage.getItem('examine')).instanceId
    this.getdetail()
  }
}
</script>
<style lang="scss" scoped>
.resolutionAudit {
  height: 100%;
}
.auditdetails {
  height: calc(100% - 32px);
}
</style>
